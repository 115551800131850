import { HttpClient } from '@angular/common/http';
import { Component, NgModule } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { CommonModule } from '@angular/common';
import { throwError } from 'rxjs/internal/observable/throwError';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  imports: [CommonModule],
})
export class FileUploadComponent {
  fileName = '';
  fileToUpload: File | null = null;
  status: 'initial' | 'uploading' | 'success' | 'fail' = 'initial'; // Variable to store file status
  files: File[] = []; // Initialize as an empty array

  constructor(private http: HttpClient) {}

  onChange(event: any) {
    const fileList: FileList = event.target.files; // Get the FileList

    if (fileList.length) {
      this.status = 'initial';
      this.files = Array.from(fileList); // Convert FileList to array
      console.log(this.files); // For debugging
    }
  }

  onUpload() {
    console.log(this.files)
    if (this.files.length) {
      const formData = new FormData();

      this.files.forEach((file) => {
        formData.append('file', file, file.name);
      });

      const upload$ = this.http.post("${url}", formData); // Ensure `url` is defined

      this.status = 'uploading';

      upload$.subscribe({
        next: () => {
          this.status = 'success';
        },
        error: (error: any) => {
          this.status = 'fail';
          return throwError(() => error);
        },
      });
    }
  }

  removeFileFromList(index: number) {
    console.log(index);
    if (index >= 0 && index < this.files.length) {
      // Check for valid index
      this.files.splice(index, 1); // Remove the file at the given index
      console.log(this.files); // Log updated files
    } else {
      console.error('Index out of bounds:', index); // Log an error if the index is invalid
    }
  }

  reset() {
    this.files = []; // Reset the files array
  }
}
